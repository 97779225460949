<template>
  <div>
    <div class="padding_top"></div>

    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="10" class="terms">
          <p class="terms__text">
            1. Определение терминов 1.1 В настоящей Политике bkteams.ru
            используются следующие термины: 1.1.1. «Администрация сайта» (далее
            – Администрация) – уполномоченные сотрудники Администрации на
            управление сайтом bkteams.ru, которые организуют и (или)
            осуществляют обработку персональных данных субъектов персональных
            данных, использующих сайт, в соответствии с целями и порядком их
            обработки, установленных в настоящей Политике. 1.1.2. «Персональные
            данные» - любая информация, относящаяся к прямо или косвенно
            определенному, или определяемому физическому лицу (субъекту
            персональных данных). 1.1.3. «Обработка персональных данных» - любое
            действие (операция) или совокупность действий (операций),
            совершаемых с использованием средств автоматизации или без
            использования таких средств с персональными данными, включая сбор,
            запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передачу (распространение,
            предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение персональных данных. 1.1.4. «Конфиденциальность
            персональных данных» - обязательное для соблюдения Оператором или
            иным получившим доступ к персональным данным лицом требование не
            допускать их распространения без согласия субъекта персональных
            данных или наличия иного законного основания. 1.1.5. «Сайт
            bkteams.ru» - это совокупность связанных между собой веб-страниц,
            размещенных в сети Интернет по уникальному адресу (URL): bkteams.ru,
            а также его субдоменах. 1.1.6. «Субдомены» - это страницы или
            совокупность страниц, расположенные на доменах третьего уровня,
            принадлежащие сайту bkteams.ru, а также другие временные страницы,
            внизу который указана контактная информация Администрации 1.1.5.
            «Пользователь сайта bkteams.ru» (далее Пользователь) – лицо, имеющее
            доступ к сайту bkteams.ru, посредством сети Интернет и использующее
            информацию, материалы и продукты сайта bkteams.ru. Пользователь
            является субъектом персональных данных. 1.1.7. «Cookies» — небольшой
            фрагмент данных, отправленный веб-сервером и хранимый на компьютере
            пользователя, который веб-клиент или веб-браузер каждый раз
            пересылает веб-серверу в HTTP-запросе при попытке открыть страницу
            соответствующего сайта. 1.1.8. «IP-адрес» — уникальный сетевой адрес
            узла в компьютерной сети, через который Пользователь получает доступ
            на bkteams.ru. 1.1.9. «Товар» - услуга, которую Пользователь
            заказывает на сайте и оплачивает через платёжные системы. 2. Общие
            положения 2.1. Использование сайта bkteams.ru Пользователем означает
            согласие с настоящей Политикой bkteams.ru и условиями обработки
            персональных данных Пользователя. 2.2. В случае несогласия с
            условиями Политики bkteams.ru Пользователь должен незамедлительно
            прекратить использование сайта bkteams.ru. 2.3. Настоящая Политика
            bkteams.ru применяется к сайту bkteams.ru. Администрация сайта не
            контролирует и не несет ответственность за сайты третьих лиц, на
            которые Пользователь может перейти по ссылкам, доступным на сайте
            bkteams.ru. 2.4. Администрация не проверяет достоверность
            персональных данных, предоставляемых Пользователем. 3. Предмет
            политики bkteams.ru 3.1. Настоящая Политика bkteams.ru устанавливает
            обязательства Администрации по неразглашению и обеспечению режима
            защиты bkteams.ru персональных данных, которые Пользователь
            предоставляет по запросу Администрации при регистрации на сайте
            bkteams.ru, при подписке на информационную e-mail рассылку или при
            оформлении заказа. 3.2. Персональные данные, разрешённые к обработке
            в рамках настоящей Политики bkteams.ru, предоставляются
            Пользователем путём заполнения форм на сайте bkteams.ru и включают в
            себя следующую информацию: 3.2.1. фамилию, имя, отчество
            Пользователя; 3.2.2. контактный телефон Пользователя; 3.2.3. половую
            принадлежность; 3.2.4. возраст; 3.2.5. страну и город проживания;
            3.2.6. адрес электронной почты (e-mail); 3.2.7. электронные
            кошельки; 3.2.8. аккаунты в социальных сетях; 3.2.9. поля форм могут
            запрашивать и иные данные. 3.3. bkteams.ru защищает Данные, которые
            автоматически передаются при посещении страниц, эти данные носят
            неперсонифицированный или обезличенный характер: - IP адрес и страна
            его регистрации; - информация из cookies; - информация о браузере -
            время доступа; - имя домена, с которого вы к нам пришли - переходы
            посетителей с одной страницы сайта на другую - иные данные,
            собираемые посещяемым сайтом и счетчиками аналитики сторонних
            организаций, установленными на сайте. 3.3.1. Отключение cookies
            может повлечь невозможность доступа к частям сайта bkteams.ru,
            требующим авторизации. 3.3.2. bkteams.ru осуществляет сбор
            статистики об IP-адресах своих посетителей. Данная информация
            используется с целью предотвращения, выявления и решения технических
            проблем. 3.4. Любая иная персональная информация неоговоренная выше
            (история посещения, используемые браузеры, операционные системы и
            т.д.) подлежит надежному хранению и нераспространению, за
            исключением случаев, предусмотренных в п.п. 5.2., 5.3., 5.7., 5.8.,
            и 5.9. настоящей Политики bkteams.ru. 4. Цели сбора персональной
            информации пользователя 4.1. Персональные данные Пользователя
            Администрация может использовать в целях: 4.1.1. Идентификации
            Пользователя, зарегистрированного на сайте bkteams.ru для его
            дальнейшей авторизации, оформления заказа и других действий. 4.1.2.
            Предоставления Пользователю доступа к персонализированным данным
            сайта bkteams.ru. 4.1.3. Установления с Пользователем обратной
            связи, включая направление уведомлений, запросов, касающихся
            использования сайта bkteams.ru, оказания услуг и обработки запросов
            и заявок от Пользователя. 4.1.4. Определения места нахождения
            Пользователя для обеспечения безопасности, предотвращения
            мошенничества. 4.1.5. Подтверждения достоверности и полноты
            персональных данных, предоставленных Пользователем. 4.1.6. Создания
            учетной записи для использования частей сайта bkteams.ru, если
            Пользователь дал согласие на создание учетной записи. 4.1.7.
            Уведомления Пользователя по электронной почте. 4.1.8. Предоставления
            Пользователю эффективной технической поддержки при возникновении
            проблем, связанных с использованием сайта bkteams.ru. 4.1.9.
            Предоставления Пользователю с его согласия специальных предложений,
            информации о ценах, новостной рассылки и иных сведений от имени
            сайта bkteams.ru. 4.1.10. Осуществления рекламной деятельности с
            согласия Пользователя. 5. Способы и сроки обработки персональной
            информации 5.1. Обработка персональных данных Пользователя
            осуществляется без ограничения срока, любым законным способом, в том
            числе в информационных системах персональных данных с использованием
            средств автоматизации или без использования таких средств. 5.2.
            Пользователь соглашается с тем, что Администрация вправе передавать
            персональные данные третьим лицам, в целях оказания заказанных на
            сайте bkteams.ru услуг, выполнении иных обязательств перед
            пользователем. К таким лицам, например, заказчики регистраций на
            других сайтах, оставления отзывов, подписки и иные. 5.3.
            Персональные данные Пользователя могут быть переданы уполномоченным
            органам государственной власти Российской Федерации только по
            основаниям и в порядке, установленным законодательством Российской
            Федерации. 5.4. При утрате или разглашении персональных данных
            Администрация вправе не информировать Пользователя об утрате или
            разглашении персональных данных. 5.5. Администрация принимает
            необходимые организационные и технические меры для защиты
            персональной информации Пользователя от неправомерного или
            случайного доступа, уничтожения, изменения, блокирования,
            копирования, распространения, а также от иных неправомерных действий
            третьих лиц. 5.6. Администрация совместно с Пользователем принимает
            все необходимые меры по предотвращению убытков или иных
            отрицательных последствий, вызванных утратой или разглашением
            персональных данных Пользователя. 5.7. На сайте bkteams.ru могут
            быть установлены формы других организаций, собирающие персональную
            информацию, в этом случае сбор, хранение и защита персональной
            информации пользователя осуществляется сторонними организациями в
            соответствии с их политикой bkteams.ru. Сбор, хранение и защита
            полученной информации от сторонней организации осуществляется в
            соответствии с настоящей политикой bkteams.ru. 5.8. Персональные
            данные пользователя могут публиковаться в общем доступе в
            соответствии с функционалом сайта bkteams.ru, например, при
            оставлении отзывов, может публиковаться указанное пользователем имя,
            такая активность на сайте является добровольной, и пользователь
            своими действиями дает согласие на такую публикацию. 5.9.
            Пользователь осознаёт и принимает возможность размещения на
            страницах Сайта программного обеспечения третьих лиц, в результате
            чего такие лица могут получать указанные в п.3.3. обезличенные
            данные. К указанному программному обеспечению третьих лиц среди
            прочего могут относиться: Системы по сбору статистики посещений
            (например, счётчики Яндекс Метрика, Google Analytics и т. д.);
            Социальные плагины (блоки) социальных сетей (например, Facebook и т.
            п.); Системы баннеропоказов (например, AdRiver и т. п.); Другие
            системы сбора обезличенной информации. Пользователь вправе
            самостоятельно ограничить сбор такой информации третьими лицами,
            используя стандартные настройки bkteams.ru применяемого им для
            работы с Сайтом интернет-браузера. 6. Права и обязанности сторон
            6.1. Пользователь вправе: 6.1.1. Принимать свободное решение о
            предоставлении своих персональных данных, необходимых для
            использования сайта bkteams.ru, и давать согласие на их обработку.
            6.1.2. Обновить, дополнить предоставленную информацию о персональных
            данных в случае изменения данной информации. 6.2. Администрация
            обязана: 6.2.1. Использовать полученную информацию исключительно для
            целей, указанных в п. 4 настоящей Политики bkteams.ru. 6.2.2.
            Обеспечить хранение конфиденциальной информации в тайне, не
            разглашать без предварительного разрешения Пользователя, а также не
            осуществлять продажу, обмен, опубликование, либо разглашение иными
            возможными способами переданных персональных данных Пользователя, за
            исключением п.п. 5.2., 5.3., 5.7., 5.8., и 5.9. настоящей Политики
            bkteams.ru. 6.2.3. Принимать меры предосторожности для защиты
            bkteams.ru персональных данных Пользователя согласно порядку, обычно
            используемого для защиты такого рода информации в существующем
            деловом обороте. 6.2.4. Осуществить блокирование персональных
            данных, относящихся к соответствующему Пользователю, с момента
            обращения или запроса Пользователя, или его законного представителя
            либо уполномоченного органа по защите прав субъектов персональных
            данных на период проверки, в случае выявления недостоверных
            персональных данных или неправомерных действий. 7. Ответственность
            сторон 7.1. Администрация не несет ответственность за какую-либо
            информацию, размещенную пользователем на сайте bkteams.ru, включая,
            но не ограничиваясь: информацию, защищенную авторским правом, без
            прямого согласия владельца авторского права. 7.2. В случае утраты
            или разглашения Конфиденциальной информации Администрация не несёт
            ответственность, если данная конфиденциальная информация: 7.2.1.
            Стала публичным достоянием до её утраты или разглашения. 7.2.2. Была
            получена от третьей стороны до момента её получения Администрацией.
            7.2.3. Была разглашена с согласия Пользователя. 7.3. Пользователь
            несет полную ответственность за соблюдение требований
            законодательства РФ, в том числе законов о рекламе, о защите
            авторских и смежных прав, об охране товарных знаков и знаков
            обслуживания, но не ограничиваясь перечисленным, включая полную
            ответственность за содержание и форму материалов. 7.4. Пользователь
            признает, что ответственность за любую информацию (в том числе, но
            не ограничиваясь: файлы с данными, тексты и т. д.), к которой он
            может иметь доступ как к части сайта bkteams.ru, несет лицо,
            предоставившее такую информацию. 7.5. Пользователь соглашается, что
            информация, предоставленная ему как часть сайта bkteams.ru, может
            являться объектом интеллектуальной собственности, права на который
            защищены и принадлежат другим Пользователям, партнерам или
            рекламодателям, которые размещают такую информацию на сайте
            bkteams.ru. Пользователь не вправе вносить изменения, передавать в
            аренду, передавать на условиях займа, продавать, распространять или
            создавать производные работы на основе такого Содержания (полностью
            или в части), за исключением случаев, когда такие действия были
            письменно прямо разрешены собственниками такого Содержания в
            соответствии с условиями отдельного соглашения. 7.6. В отношение
            текстовых материалов (статей, публикаций, находящихся в свободном
            публичном доступе на сайте bkteams.ru) допускается их
            распространение при условии, что будет дана ссылка на bkteams.ru.
            7.7. Администрация не несет ответственности перед Пользователем за
            любой убыток или ущерб, понесенный Пользователем в результате
            удаления, сбоя или невозможности сохранения какого-либо Содержания и
            иных коммуникационных данных, содержащихся на сайте bkteams.ru или
            передаваемых через него. 7.8. Администрация не несет ответственности
            за любые прямые или косвенные убытки, произошедшие из-за:
            использования либо невозможности использования сайта, либо отдельных
            сервисов; несанкционированного доступа к коммуникациям Пользователя;
            заявления или поведение любого третьего лица на сайте. 8.
            Дополнительные условия 8.1. Настоящая Политика bkteams.ru может быть
            изменена или прекращена Администрацией в одностороннем порядке без
            предварительного уведомления Пользователя. 8.2. Новая Политика
            bkteams.ru вступает в силу с момента ее размещения на сайте
            bkteams.ru, если иное не предусмотрено новой редакцией Политики
            bkteams.ru. 8.3. Все предложения или вопросы касательно настоящей
            Политики bkteams.ru следует сообщать по адресу: support@bkteams.ru
            8.4. Действующая Политика bkteams.ru размещена на странице по адресу
            bkteams.ru/terms_of_use
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
};
</script>

<style lang="scss">
.terms {
  background-color: $white;
}
.terms__text {
  color: $black;
}
</style>
